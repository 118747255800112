import { UserOrganizationState } from './../../user-organization/state/index';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { AuthState } from '@mentor-one-ui/core/state/auth/auth.state';
import { authReducer } from '@mentor-one-ui/core/state/auth/auth.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { userReducer } from '@mentor-one-ui/core/state/user/user.reducer';
import { UserState } from '@mentor-one-ui/core/state/user/user.state';
import { UserActions } from './user/user.actions';
import { translationReducer } from './translations/translation.reducer';
import { TranslationState } from './translations/translation.state';
import * as fromUserOrganization from '@mentor-one-ui/user-organization/state/index';
import * as fromApplication from './application/application.reducer';
import * as fromFrontpage from './frontpage/frontpage.reducer';
import * as fromEmployees from '@mentor-one-ui/employees/state/index';
import * as fromSetPassword from '@mentor-one-ui/set-password/state/set-password.reducer';
import * as authActions from '@mentor-one-ui/core/state/auth/auth.actions';
import * as fromTime from '@mentor-one-ui/time/state/index';
import * as fromReport from './report/report.reducer';

export interface State {
  // employees: EmployeesState;
  application: fromApplication.State;
  frontpage: fromFrontpage.State;
  router: RouterReducerState;
  auth: AuthState;
  user: UserState;
  translations: TranslationState;
  organization: UserOrganizationState;
  employees: fromEmployees.EmployeesState;
  setpassword: fromSetPassword.State;
  time: fromTime.TimeState;
  report: fromReport.State;
  // contacts: ContactState;
}

export const ROOT_REDUCERS: ActionReducerMap<State> = {
  application: fromApplication.applicationReducer,
  frontpage: fromFrontpage.frontpageReducer,
  router: routerReducer,
  auth: authReducer,
  user: userReducer,
  translations: translationReducer,
  organization: fromUserOrganization.reducers,
  employees: fromEmployees.reducers,
  setpassword: fromSetPassword.reducers,
  time: fromTime.reducers,
  report: fromReport.reportReducer
  // contacts: contactReducer,
};

/**
 * Reset a partial state when the RESET action is dispatched,
 * except for the authentication and design states
 */
function flush(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: State | undefined, action: Action) {
    if (action.type === UserActions.resetStateAfterOrganizationSwitch.type || action.type === authActions.logout.type) {
      return reducer(reducer, action);
    }

    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    rehydrate: true,
    restoreDates: false,
    keys: [
      { user: ['employeeId'] },
      'application',
      { frontpage: ['systemMessageList', 'manageList', 'dailySummary'] },
      'auth',
      { time: [{ calendar: [{ filter: ['selectedDepartmentIds'] }] }] },
      // 'myTime',
      // { 'myTime': [{ absence: ['entities', 'ids'] }, { overtime: ['entities', 'ids'] }] },
      {
        employees: [
          {
            employees: [{ filter: ['departments', 'selectedColumns', 'columns'] }],
          },
        ],
      },
      // {leaveManagement: ['leaveApproval']},
      { translations: ['selectedLanguage'] },
    ],
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [localStorageSyncReducer, flush]
  : [localStorageSyncReducer, flush];
