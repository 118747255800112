import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';
import { AppPermissionEnum } from '@mentor-one-ui/core/permissions/app-permissions';
import { selectUserRole } from '../access/access.selector';

const selectUserState = createFeatureSelector<UserState>('user');
const selectUser = createSelector(selectUserState, (state) => state.user);
const selectUserCultureCode = createSelector(selectUserState, (state) => state.user?.CultureCode);
const selectAvailabeOrganziations = createSelector(selectUserState, (state) => state.availableOrganizations);
const selectModuleAccess = createSelector(selectUserState, (state) => state?.moduleAccess);
const selectSectorsForDropDown = createSelector(selectUserState, (state) => state?.sectors);
const selectSelectedUserId = createSelector(selectUserState, (state) => state?.employeeId);
const selectHideSupportBoxForEmployee = createSelector(selectUserState, (state) => state.user?.HideSupportBox);
const selectCurrentOrganizationId = createSelector(selectUserState, (state) => state.user?.OrganizationId);
const selectCurrentOrganizationName = createSelector(selectUserState, (state) => state.user?.OrganizationName);
const selectSelectedUserFullName = createSelector(selectUserState, (state) => state.user?.EmployeeFullName);
const selectSystemAccessRoles = createSelector(selectUserState, (state) => state.availableSystemAccessRoles);
const selectUserToken = createSelector(selectUserState, (state) => state?.user?.UserToken);

const hasSystemAccess = (systemAccess: number) =>
  createSelector(selectSystemAccessRoles, (roles) => roles.some((r) => r.System === systemAccess));
export const UserSelectors = {
  selectUser,
  selectUserCultureCode,
  selectAvailabeOrganziations,
  selectModuleAccess,
  selectSectorsForDropDown,
  selectSelectedUserId,
  selectHideSupportBoxForEmployee,
  selectCurrentOrganizationId,
  selectCurrentOrganizationName,
  selectSelectedUserFullName,
  selectSystemAccessRoles,
  selectUserToken,
  hasSystemAccess,
};

export const selectUserPersonnelManagers = createSelector(selectUser, (user) => user?.PersonnelManagers ?? []);

export const selectHasPermission = (permission: AppPermissionEnum) =>
  createSelector(selectUserRole, (role) => {
    let hasPermission = false;
    if (permission === AppPermissionEnum.IsMentorPersonalAdmin) {
      if (role.isMentorPersonalAdministrator) {
        hasPermission = true;
      }
    }
    if (permission === AppPermissionEnum.IsMentorPersonalAdminOrPersonnelManager) {
      if (role.isMentorPersonalAdministrator || role.isPersonnelManager) {
        hasPermission = true;
      }
    }
    return hasPermission;
  });

export const showSupportLogin = (orgId: number) =>
  createSelector(selectAvailabeOrganziations, (organizations) => {
    return !organizations.some((o) => o.OrganizationId == orgId);
  });

export const isSupportUserInCurrentOrganization = createSelector(
  selectAvailabeOrganziations,
  selectCurrentOrganizationId,
  (organizations, currentOrganizationId) => {
    let org = organizations.find((o) => o.OrganizationId == currentOrganizationId);
    if (org?.IsSupportLogin == true) {
      return true;
    }
    return false;
  }
);

export const selectManageableEmployeesIsLoaded = createSelector(
  selectUserState,
  (state) => state.manageableEmployeesLoaded
);
export const selectManageableEmployeeIds = createSelector(selectUserState, (state) => state.manageableEmployees);

export const selectCanManageEmployee = (employeeId: number) =>
  createSelector(selectManageableEmployeeIds, (manageableEmployees) =>
    manageableEmployees?.some((id) => id === employeeId)
  );

export const selectJobTitles = createSelector(selectUserState, (state) => state.jobTitles);

export const selectPersonnelManagers = createSelector(selectUserState, (state) => state?.personnelManagers);

export const selectOrganizationHasPayrollFromPbl = createSelector(selectUserState, (state) => {
  return state?.moduleAccess
    ?.find((s: any) => s.System === 1)
    ?.Modules.find((m: any) => m.ModuleCode === '794e9130-0e4f-4972-b960-78ff0de30334')?.HasAccess;
});

export const selectIsUserLoaded = createSelector(selectUserState, (state) => state.user !== null);
