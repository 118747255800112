import { createSelector } from "@ngrx/store";
import { selectTimeCommonState } from ".";

  export const selectAbsenceTypes = createSelector(
    selectTimeCommonState,
    state => {
      return state?.absenceTypes.filter(absenceType => absenceType.IsActive) ?? [];
    }
  );

  export const selectAllAbsenceTypes = createSelector(
    selectTimeCommonState,
    state => {
      return state?.absenceTypes ?? [];
    }
  );

  export const selectAbsenceTypeById = (id: number) => createSelector(selectAbsenceTypes, (allItems) => {
    if (!allItems) return null;
    return allItems.find(absenceType => absenceType.OrganizationAbsenceMapId === id) ?? null;
  });
  
  export const selectOvertimeTypes = createSelector(
    selectTimeCommonState,
    state => state?.overtimeTypes.filter(overtimeType => overtimeType.IsActive) ?? []
  );

  export const selectAllOvertimeTypes = createSelector(
    selectTimeCommonState,
    state => state?.overtimeTypes ?? []
  );
  
  export const selectOvertimeTypeById = (id: number) => createSelector(selectOvertimeTypes, (allItems) => {
    if (!allItems) return null;
    return allItems.find(overtimeType => overtimeType.OrganizationOvertimeMapId === id) ?? null;
  });
  
  export const selectAbsenceTypesLoadError = createSelector(
    selectTimeCommonState,
    state => state?.absenceTypesLoadError ?? false
  );
  
  export const selectAbsenceTypesLoaded = createSelector(
    selectTimeCommonState,
    state => state?.absenceTypesLoaded ?? false
  );
  
  export const selectOvertimeTypesLoaded = createSelector(
    selectTimeCommonState,
    state => state?.overtimeTypes?.length > 0
  );
  
  export const selectOvertimeTypesLoadError = createSelector(
    selectTimeCommonState,
    state => state?.overtimeTypesLoadError
  );