import { createAction, props } from "@ngrx/store";
import { OvertimeModel } from "../models/overtime.model";

export const OvertimePageActions = {
  Enter: createAction('[Overtime Page] Enter'),
  LoadOvertimeTypes: createAction('[Overtime Page] Load Overtime Types'),
  LoadOvertime: createAction('[Overtime Page] Load Overtime',
  props<{ overtime: OvertimeModel }>()),
  changePage: createAction('[Overtime Page] Change Page', props<{ page: number }>()),
  filterByOvertimeType: createAction('[Overtime Page] Filter By Overtime Type', props<{ overtimeTypeIds: number[] }>()),  
  filterByStatus: createAction('[Overtime Page] Filter By Overtime Status', props<{ status: number }>()),
  resetFilter: createAction('[Overtime Page] Reset Filter'),
};

export const EditOvertimePageActions = {
  GoBack: createAction('[Edit Overtime Page] Go Back'),
  UpdateOvertime: createAction('[Edit Overtime Page] Edit Overtime',
  props<{ overtime: OvertimeModel }>()),

  HasFormChanges: createAction('[Edit Overtime Page] Has Form Changes',
  props<{ hasChanges: boolean }>()),

  DeleteOvertime: createAction('[Overtime Details Page] Delete Overtime',
  props<{ overtimeId: number, isExported: boolean | null }>()),

  DeleteOvertimeConfirmed: createAction('[Overtime Details Page] Delete Overtime Confirmed'),

  DeleteOvertimeCanceled: createAction('[Overtime Details Page] Delete Overtime Canceled')
}
