import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { AbsenceModel } from './models/absence.model';
import { TimeManagementListModel } from './models/TimeManagementModel';
import { OvertimeModel } from '../overtime/models/overtime.model';
import { TimeValidatorService } from '@mentor-one-ui/time/shared/time-validator.service';
import { TimeManagementStatusEnum } from './models/TimeManagementStatusEnum';
import { TimeFilter } from '@mentor-one-ui/time/shared/time-filter.model';

@Injectable({
  providedIn: 'root',
})
export class AbsenceManageService {
  constructor(public http: HttpClient, private timeValidatorService: TimeValidatorService) {}

  getAbsences(
    filter: TimeFilter,
    page: number = 0,
    statuses: number[],
    filterAssignedToCurrentUser: boolean,
    take: number = 10
  ): Observable<TimeManagementListModel> {
    const body = {
      DepartmentIds: filter.selectedDepartmentIds,
      AbsenceMapIds: filter.absenceMapIds,
      OvertimeMapIds: filter.overtimeMapdIds,
      JobTitles: filter.selectedPositions,
      EmployeeId: filter.selectedEmployeeId != -1 ? filter.selectedEmployeeId : null,
      Skip: page * 10,
      Take: take,
      Sorting: filter.selectedSortOptionId,
      Statuses: statuses[0] === -1 ? [TimeManagementStatusEnum.Rejected, TimeManagementStatusEnum.Approved] : statuses,
      FilterAssignedToCurrentUser: filterAssignedToCurrentUser,
    };

    return this.http.post<TimeManagementListModel>(environment.API_URL + '/TimeManagement/list', body).pipe(
      map((result) => {
        result.Items.map((leave) => {
          leave.FromTime = this.timeValidatorService.removeSecondsFromTimeString(leave.FromTime);
          leave.ToTime = this.timeValidatorService.removeSecondsFromTimeString(leave.ToTime);
          leave.HoursCalculated = this.timeValidatorService.removeSecondsFromTimeString(leave.HoursCalculated);
        });
        return result;
      })
    );
  }

  approveAbsence(absenceId: number): Observable<AbsenceModel> {
    const body = {
      newStatus: 1,
    };
    return this.http.post<AbsenceModel>(environment.API_URL + '/Absence/' + absenceId + '/updateStatus', body);
  }

  rejectAbsence(absenceId: number, comment: string): Observable<AbsenceModel> {
    const body = {
      comment: comment,
      newStatus: 2,
    };
    return this.http.post<AbsenceModel>(environment.API_URL + '/Absence/' + absenceId + '/updateStatus', body);
  }

  approveOvertime(absenceId: number): Observable<OvertimeModel> {
    const body = {
      newStatus: 1,
    };
    return this.http.post<OvertimeModel>(environment.API_URL + '/Overtime/' + absenceId + '/updateStatus', body);
  }

  rejectOvertime(overtimeId: number, comment: string): Observable<OvertimeModel> {
    const body = {
      comment: comment,
      newStatus: 2,
    };

    return this.http.post<OvertimeModel>(environment.API_URL + '/Overtime/' + overtimeId + '/updateStatus', body);
  }

  registerBulkAbsence(employeeIds: number[], absence: AbsenceModel, AutomaticApprove: boolean) {
    const body = {
      EmployeeIds: employeeIds,
      Model: absence,
      AutomaticApprove: AutomaticApprove,
    };
    return this.http.post(environment.API_URL + '/Absence/multiple/add', body);
  }

  registerBulkOvertime(employeeIds: number[], overtime: OvertimeModel, AutomaticApprove: boolean) {
    const body = {
      EmployeeIds: employeeIds,
      Model: overtime,
      AutomaticApprove: AutomaticApprove,
    };
    return this.http.post(environment.API_URL + '/Overtime/multiple/add', body);
  }
}
