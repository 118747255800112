import { createAction, props } from '@ngrx/store';
import { PayrollModel } from '../../models/payroll.model';
import { EmployeePayrollModel } from '../../models/employee-payroll.model';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { PayrollExportSettingModel } from '../../models/payroll-settings.model';
import { PayrollValidationResultModel } from '../../models/payroll-valiation-result.model';

export const PayrollApiActions = {
  validatePayrollSettingsSuccess: createAction(
    '[Payroll API] Validate Payroll Settings Success',
    props<{ validationResult: PayrollValidationResultModel }>()
  ),
  validatePayrollSettingsFailure: createAction(
    '[Payroll API] Validate Payroll Settings Failure',
    props<{ error: string }>()
  ),
  loadPayrollOverviewSuccess: createAction(
    '[Payroll API] Load Payroll Overview Success',
    props<{ payrollList: PayrollModel[] }>()
  ),
  loadPayrollOverviewFailure: createAction('[Payroll API] Load Payroll Overview Failure', props<{ error: string }>()),
  loadEmployeePayrollSuccess: createAction(
    '[Payroll API] Load Employee Payroll Success',
    props<{ employeeReport: EmployeePayrollModel }>()
  ),
  loadEmployeePayrollFailure: createAction('[Payroll API] Load Employee Payroll Failure', props<{ error: string }>()),
  certifyEmployeePayrollSuccess: createAction('[Payroll API] Certify Employee Payroll Success'),
  certifyEmployeePayrollFailure: createAction(
    '[Payroll API] Certify Employee Payroll Failure',
    props<{ error: string }>()
  ),
  ApproveLeaveSuccess: createAction(
    '[Payroll API] Approve Absence Success',
    props<{ id: number; leaveType: TimeManagementType }>()
  ),
  ApproveLeaveError: createAction('[Payroll API] Approve Absence Error', props<{ error: string }>()),
  RejectAbsenceSuccess: createAction(
    '[Payroll API] Reject Absence Success',
    props<{ id: number; leaveType: TimeManagementType }>()
  ),
  RejectAbsenceError: createAction('[Payroll API] Reject Absence Error', props<{ error: string }>()),
  exportPayrollSuccess: createAction('[Payroll API] Export Payroll Success'),
  exportPayrollFailure: createAction('[Payroll API] Export Payroll Failure', props<{ error: string }>()),
};

export const PayrollSettingsApiActions = {
  loadPayrollSettingsSuccess: createAction(
    '[Payroll Settings API] Load Payroll Settings Success',
    props<{ settings: PayrollExportSettingModel }>()
  ),
  loadPayrollSettingsFailure: createAction(
    '[Payroll Settings API] Load Payroll Settings Failure',
    props<{ error: string }>()
  ),
  updatePayrollSettingsSuccess: createAction(
    '[Payroll Settings API] Update Payroll Settings Success',
    props<{ settings: PayrollExportSettingModel }>()
  ),
  updatePayrollSettingsFailure: createAction(
    '[Payroll Settings API] Update Payroll Settings Failure',
    props<{ error: string }>()
  ),
};
