import { createReducer, on } from '@ngrx/store';
import { PayrollModel } from '../models/payroll.model';
import { PayrollApiActions, PayrollSettingsApiActions } from './actions/payroll-api.actions';
import {
  EmployeePayrollReportActions,
  PayrollPageActions,
  PayrollSettingsPageActions,
} from './actions/payroll-page.actions';
import { PayrollFilter } from '../models/payroll-filter.model';
import { DateOnlyConverter } from '@mentor-one-ui/core/misc/dateonly.converter';
import { EmployeePayrollModel } from '../models/employee-payroll.model';
import { TimeManagementStatusEnum } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum';
import { PayrollExportSettingModel } from '../models/payroll-settings.model';
import { PayrollValidationResultModel } from '../models/payroll-valiation-result.model';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';

export const payrollFeatureKey = 'payroll';

export interface State {
  payrollList: PayrollModel[];
  isLoading: boolean;
  isLoadingEmployeePayroll: boolean;
  isSaving: boolean;
  isValidating: boolean;
  loadError: boolean;
  isCertifyingEmployeePayroll: boolean;
  filter: PayrollFilter;
  EmployeePayroll: EmployeePayrollModel | null;
  selectedId: number;
  selectedEmployeeId: number;
  settings: PayrollExportSettingModel | null;
  isSavingSettings: boolean;
  payrollValidation: PayrollValidationResultModel | null;
  settingsHasChanges: boolean;
}

export const initialState: State = {
  payrollList: [],
  isLoading: false,
  isSaving: false,
  isValidating: false,
  isLoadingEmployeePayroll: true,
  isSavingSettings: false,
  isCertifyingEmployeePayroll: false,
  filter: {
    startDate: '',
    endDate: '',
    departmentIds: [],
  },
  EmployeePayroll: null,
  selectedId: -1,
  loadError: false,
  selectedEmployeeId: -1,
  settings: null,
  payrollValidation: null,
  settingsHasChanges: false,
};

export const reducer = createReducer(
  initialState,
  on(PayrollPageActions.Enter, (state): State => {
    const showLoading = state.payrollList.length == 0;

    var today = new Date();
    var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    return {
      ...state,
      isLoading: showLoading,
      loadError: false,
      EmployeePayroll: null,
      isLoadingEmployeePayroll: true,
      filter: {
        startDate: DateOnlyConverter.convertJsDateToDateString(firstDay),
        endDate: DateOnlyConverter.convertJsDateToDateString(lastDay),
        departmentIds: [],
      },
    };
  }),
  on(PayrollPageActions.ValidatePayrollSettings, (state): State => {
    return {
      ...state,
      isValidating: true,
    };
  }),
  on(PayrollApiActions.validatePayrollSettingsSuccess, (state, action): State => {
    return {
      ...state,
      isValidating: false,
      payrollValidation: action.validationResult,
    };
  }),
  on(PayrollApiActions.validatePayrollSettingsFailure, (state): State => {
    return {
      ...state,
      isValidating: false,
    };
  }),
  on(PayrollPageActions.FilterChanged, (state, { filter }): State => {
    return {
      ...state,
      isLoading: true,
      payrollList: [],
      filter: filter,
    };
  }),
  on(PayrollPageActions.OpenEmployeeReport, (state, action): State => {
    return {
      ...state,
      EmployeePayroll: null,
      isLoadingEmployeePayroll: true,
      loadError: false,
      selectedEmployeeId: action.employeeId,
    };
  }),
  on(EmployeePayrollReportActions.CertifyEmployeePayroll, (state): State => {
    return {
      ...state,
      isCertifyingEmployeePayroll: true,
    };
  }),
  on(PayrollPageActions.ExportPayrollModal, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(EmployeePayrollReportActions.ApproveLeaveRequest, (state, { leaveId }): State => {
    return {
      ...state,
      isSaving: true,
      selectedId: leaveId,
    };
  }),
  on(PayrollApiActions.ApproveLeaveSuccess, (state, { id, leaveType: timeType }): State => {
    let unprocessedEmployeePayroll = state.EmployeePayroll?.Unprocessed! - 1;

    let items = state.EmployeePayroll?.Items.map((m) => {
      if (m.Id == id && m.Type == timeType) {
        return { ...m, Status: TimeManagementStatusEnum.Approved };
      } else {
        return m;
      }
    });

    let payrollItems = [...state.payrollList].map((m) => {
      if (m.EmployeeId == state.EmployeePayroll?.EmployeeId) {
        return { ...m, Unprocessed: m.Unprocessed - 1 };
      }
      return m;
    });

    return {
      ...state,
      isSaving: false,
      selectedId: -1,
      payrollList: payrollItems,
      EmployeePayroll: {
        ...state.EmployeePayroll,
        Unprocessed: unprocessedEmployeePayroll,
        Items: items!,
      } as EmployeePayrollModel,
    };
  }),
  on(EmployeePayrollReportActions.RejectLeaveRequest, (state, { leaveId }): State => {
    return {
      ...state,
      isSaving: true,
      selectedId: leaveId,
    };
  }),
  on(PayrollApiActions.RejectAbsenceSuccess, (state, { id, leaveType }): State => {
    let unprocessedEmployeePayroll = state.EmployeePayroll?.Unprocessed! - 1;

    let items = state.EmployeePayroll?.Items.filter((m) => m.Id != id);

    let payrollItems = [...state.payrollList].map((m) => {
      if (m.EmployeeId == state.EmployeePayroll?.EmployeeId) {
        return { ...m, Unprocessed: m.Unprocessed - 1 };
      }
      return m;
    });

    return {
      ...state,
      isSaving: false,
      selectedId: -1,
      payrollList: payrollItems,
      EmployeePayroll: {
        ...state.EmployeePayroll,
        Unprocessed: unprocessedEmployeePayroll,
        Items: items!,
      } as EmployeePayrollModel,
    };
  }),
  on(PayrollApiActions.loadPayrollOverviewSuccess, (state, { payrollList }): State => {
    return {
      ...state,
      payrollList: payrollList,
      isLoading: false,
    };
  }),
  on(PayrollApiActions.loadPayrollOverviewFailure, (state): State => {
    return {
      ...state,
      isLoading: false,
      loadError: true,
    };
  }),
  on(PayrollApiActions.loadEmployeePayrollSuccess, (state, { employeeReport }): State => {
    return {
      ...state,
      EmployeePayroll: employeeReport,
      isLoadingEmployeePayroll: false,
      loadError: false,
    };
  }),
  on(PayrollApiActions.loadEmployeePayrollFailure, (state): State => {
    return {
      ...state,
      isLoadingEmployeePayroll: false,
      EmployeePayroll: null,
      loadError: true,
    };
  }),
  on(PayrollApiActions.certifyEmployeePayrollSuccess, (state): State => {
    return {
      ...state,
      isCertifyingEmployeePayroll: false,
    };
  }),
  on(PayrollApiActions.certifyEmployeePayrollFailure, (state): State => {
    return {
      ...state,
      isCertifyingEmployeePayroll: false,
    };
  }),
  on(PayrollApiActions.exportPayrollSuccess, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(PayrollApiActions.exportPayrollFailure, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(PayrollSettingsPageActions.UpdatePayrollSettings, (state): State => {
    return {
      ...state,
      isSavingSettings: true,
    };
  }),
  on(PayrollSettingsApiActions.loadPayrollSettingsSuccess, (state, { settings }): State => {
    return {
      ...state,
      settings: settings,
    };
  }),
  on(PayrollSettingsApiActions.updatePayrollSettingsSuccess, (state, { settings }): State => {
    return {
      ...state,
      isSavingSettings: false,
      settingsHasChanges: false,
      settings: settings,
    };
  }),
  on(PayrollSettingsApiActions.updatePayrollSettingsFailure, (state): State => {
    return {
      ...state,
      isSavingSettings: false,
    };
  }),
  on(PayrollSettingsPageActions.HasFormChanges, (state, { hasChanges }): State => {
    return {
      ...state,
      settingsHasChanges: hasChanges,
    };
  }),
  on(ApplicationActions.DiscardUnsavedChanges, (state) => ({
    ...state,
    settingsHasChanges: false,
  }))
);
