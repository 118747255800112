import { selectRoutedOvertimeId } from "@mentor-one-ui/core/state/router/router.selectors";
import { createSelector } from "@ngrx/store";
import { NewOvertimeViewModel } from "../models/new-overtime-view.model";
import { adapter } from "../../state/overtime.reducer";
import { OvertimePageViewModel } from "../models/overtime-page-view.model";
import { selectOvertimeTypes, selectOvertimeTypesLoaded, selectOvertimeTypesLoadError } from "@mentor-one-ui/time/state/time-common/selectors/time-common.selectors";
import { selectMyTimeState } from "../../state/selectors";
import * as fromMyTime from "../../state";
import { selectUserPersonnelManagers } from "@mentor-one-ui/core/state/user/user.selector";
import { selectBalance } from "../../balance/state/balance.selectors";

export const selectOvertimeState = createSelector(
  selectMyTimeState,
  (state: fromMyTime.State) => state?.overtime
);

export const {
  selectIds: selectOvertimeIds,
  selectEntities: selectOvertimeEntities,
  selectAll: selectAllOvertimes,
  selectTotal: selectTotalOvertimes,
} = adapter.getSelectors(selectOvertimeState);

export const selectSelectedOvertime = createSelector(
  selectRoutedOvertimeId,
  selectAllOvertimes,
  (overtimeId, overtimes) => {
    if (!overtimeId || !overtimes) return null;
    return overtimes?.find((overtime) => overtime.OvertimeId === +overtimeId) ?? null;
  }
);

export const selectSelectedOvertimeViewModel = createSelector(
  selectSelectedOvertime,
  selectOvertimeTypes,
  selectBalance,
  (overtime, overtimeTypes, balance) => {
    if (!overtime || !overtimeTypes) return null;
    return {
      Overtime: overtime,
      OvertimeType: overtimeTypes.find((overtimeType) => overtimeType.OrganizationOvertimeMapId === overtime.OrganizationOvertimeMapId),
      Balance: balance
    }
  }
);

export const selectIsSaving = createSelector(
  selectOvertimeState,
  state => state?.isSaving
);

export const selectSelectedOvertimeTypeId = createSelector(
  selectOvertimeState,
  state => state?.selectedOvertimeTypeId
);

export const selectSelectedOvertimeType = createSelector(
  selectOvertimeTypes,
  selectSelectedOvertimeTypeId,
  (overtimeTypes, selectedOvertimeTypeId) => {
    return overtimeTypes?.find((overtimeType) => overtimeType.OrganizationOvertimeMapId === selectedOvertimeTypeId) ?? null;
  }
);

export const selectHasFormChanges = createSelector(
  selectOvertimeState,
  state => state?.hasFormChanges
);

export const selectOvertimeLoaded = createSelector(
  selectOvertimeState,
  state => {
    return state?.overtimeLoaded
  }
);

export const selectTotalOvertimeCount = createSelector(
  selectOvertimeState,
  state => state?.totalItems
);

export const selectPageSize = createSelector(
  selectOvertimeState,
  state => state?.pageSize
);

export const selectPage = createSelector(
  selectOvertimeState,
  state => state?.page
);

export const selectOvertimeLoadError = createSelector(
  selectOvertimeState,
  state => state?.overtimeLoadError
);

export const selectFilterByType = createSelector(
  selectOvertimeState,
  state => {
    return  state?.filteredOvertimeTypes}
);

export const selectFilterByStatus = createSelector(
  selectOvertimeState,
  state => state?.filterByStatus
);



export const selectOvertimePageViewModel = createSelector(
  selectAllOvertimes,
  selectOvertimeTypes,
  selectOvertimeTypesLoaded,
  selectOvertimeTypesLoadError,
  selectOvertimeLoadError,
  selectOvertimeLoaded,
  selectTotalOvertimeCount,
  selectPageSize,
  selectPage,
  (overtimes,
    overtimeTypes,
    overtimeTypesLoaded,
    overtimeTypesLoadError,
    overtimeLoadError,
    overtimeLoaded,
    totalItems,
    pageSize,
    page) => {
    return {
      overtimes,
      overtimeTypes,
      loadComplete: (overtimeTypesLoaded && overtimeLoaded),
      showError: (overtimeTypesLoadError || overtimeLoadError),
      pageSize: pageSize,
      totalItems: totalItems,
      page
    } as OvertimePageViewModel
  });

export const selectNewOvertimePageViewModel = createSelector(
  selectOvertimeTypes,
  selectSelectedOvertimeType,
  selectIsSaving,    
  selectOvertimeTypesLoadError,
  selectUserPersonnelManagers,
  selectBalance,
  (overtimeTypes,
    selectedOvertimeType,
    isSaving,
    overtimeTypesLoadError, 
    personnelManagers,
    balance) => {
    return {
      overtimeTypes,
      selectedOvertimeType,
      isSaving,
      showError: overtimeTypesLoadError,
      personnelManagers: personnelManagers,
      balance
    } as NewOvertimeViewModel
  }
);

