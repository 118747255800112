import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonBarComponent } from './button-bar.component';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ButtonBarComponent],
  exports: [ButtonBarComponent],
  imports: [CommonModule, ButtonModule, RouterModule],
})
export class ButtonBarModule {}
