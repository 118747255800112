import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, timeout } from 'rxjs/operators';
import { LoggerService } from '@mentor-one-ui/core/services/logger.service';
import { AuthService } from '../state/auth/services/auth.service';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private logger: LoggerService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(20000),
      retry(1),
      catchError((error: HttpErrorResponse) => {
        this.logger.logHttpErrorResponse(error);
        if (error.status === 401) {
          let responseContent = error.error;
          if (responseContent !== null && responseContent !== undefined) {
            if (responseContent == 'ResponseCode: no-access') {
              this.router.navigate(['/no-access']);
              next.handle(request);
            }
            if (responseContent == 'ResponseCode: organization-changed') {
              window.location.href = '/';
              next.handle(request);
            }
          } else {
            this.authService.checkAuth().subscribe((res) => {
              if (!res.isAuthenticated) {
                this.authService.doLogin();
              }
            });
          }
          return throwError(() => new Error(error.error));
        } else if (error.status === 403) {
          const navigationExtras: NavigationExtras = {
            state: { url: request.urlWithParams },
          };
          this.router.navigate(['/forbidden'], navigationExtras);
        } else if (error.status === 404) {
          const navigationExtras: NavigationExtras = {
            state: { url: request.urlWithParams },
          };
          this.router.navigate(['/not-found'], navigationExtras);
        }
        return throwError(() => new Error(error.error));
      })
    );
  }
}
