import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { createAction, props } from '@ngrx/store';

export const SettingsPageActions = {
  saveSettings: createAction(
    '[Organization - Settings | PAGE] Save organization',
    props<{ organization: UserOrganizationModel }>()
  ),
  openAbsenceModal: createAction(
    '[Organization - Settings - Absence | TAB] Open modal'
  ),
  openFormalityModal: createAction(
    '[Organization - Settings - Formality | TAB] Open modal'
  ),
};

export const SettingsApiActions = {
  saveSettingsSuccess: createAction(
    '[Organization API] Save Organization Success',
    props<{ organization: UserOrganizationModel }>()
  ),
  saveSettingsFailure: createAction(
    '[Organization API] Save organization Error',
    props<{ error: string }>()
  ),
};

export const SettingsModalActions = {
  CancelModal: createAction('[Organization - Settings | MODAL] Modal Cancel Click', props<{ hasChanges: boolean }>()),
  CloseModalRejected: createAction('[Organization - Settings | MODAL] Modal Cancel Rejected'),
  CloseModalConfirmed: createAction('[Organization - Settings | MODAL] Modal Cancel Confirmed'),
  SaveModal: createAction('[Organization - Settings | MODAL] Modal Save Click', props<{ hasChanges: boolean }>()),
  CloseModal: createAction('[Organization - Settings | MODAL] Modal Close Click'),
};
