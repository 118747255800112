import { createReducer, on } from "@ngrx/store";
import { ApplicationActions } from "@mentor-one-ui/core/state/application/application.actions";
import { AbsenceApiActions } from "../absence/actions/absence-api.actions";
import { NewAbsencePageActions, AbsencePageActions, EditAbsencePageActions } from "../absence/actions/absence-page.actions";
import { AbsenceModel } from "../absence/models/absence.model";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";

export const featureKey = 'absence';

export interface State extends EntityState<AbsenceModel>{
  isSaving: boolean;
  isLoading: boolean;
  selectedAbsenceTypeId: number;
  hasFormChanges: boolean;
  absencesLoaded: boolean;
  totalItems: number;
  pageSize: number;
  page: number;
  absenceLoadError: boolean;
  filteredAbsenceTypes: number[];
  filterByStatus: number;  
}

export const adapter: EntityAdapter<AbsenceModel> = createEntityAdapter<AbsenceModel>({
  selectId: (a) => a.AbsenceId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  isSaving: false,
  isLoading: false,
  selectedAbsenceTypeId: -1,
  hasFormChanges: false,
  absencesLoaded: false,
  totalItems: 0,
  pageSize: 10,
  page: 0,
  absenceLoadError: false,
  filteredAbsenceTypes: [],
  filterByStatus: -1
});



export const reducer = createReducer(
  initialState,
  on(NewAbsencePageActions.Enter, AbsencePageActions.Enter, (state) => {
    return {
      ...state,
      selectedAbsenceTypeId: -1,
      absenceLoadError: false
    }
  }),
  on(AbsencePageActions.SelectAbsenceType, (state, { absenceTypeId }): State => {
    return {
      ...state,
      selectedAbsenceTypeId: absenceTypeId
    }
  }),
  on(AbsencePageActions.resetAbsenceTypeSelection, (state): State => {
    return {
      ...state,
      selectedAbsenceTypeId: -1
    }
  }),
  on(AbsencePageActions.loadAbsence, (state, { absence }): State => {
    return {
      ...adapter.addOne(absence, state),
      absenceLoadError: false
    }
  }
  ),  
  on(AbsenceApiActions.loadAbsencesSuccess, (state, { absences, totalItems }): State => {
    return {
      ...adapter.setAll(absences, state),      
      absencesLoaded: true,
      totalItems: totalItems,
      absenceLoadError: false
    }
  }),
  on(AbsencePageActions.Register,
    EditAbsencePageActions.EditAbsence,
    (state) => ({
      ...state,
      isSaving: true
    })),
  on(AbsenceApiActions.registerAbsenceSuccess, (state, { absence }) => ({
    ...adapter.addOne(absence, state),
    isSaving: false,
    hasFormChanges: false
  })),
  on(AbsenceApiActions.UpdateAbsenceSuccess, (state, { absence }) => ({
    ...adapter.updateOne({ id: absence.AbsenceId, changes: absence }, state),
    isSaving: false,
    hasFormChanges: false
  })),
  on(AbsenceApiActions.loadAbsencesError, (state) => ({
    ...state,
    absenceLoadError: true
  })),
  on(AbsenceApiActions.registerAbsenceError, AbsenceApiActions.UpdateAbsenceError, (state) => ({
    ...state,
    isSaving: false
  })),
  on(AbsenceApiActions.DeleteAbsenceSuccess, (state, {absenceId }) => ({        
    ...adapter.removeOne(absenceId, state),
    isSaving: false,
    hasFormChanges: false
  })),
  on(NewAbsencePageActions.HasFormChanges, EditAbsencePageActions.HasFormChanges, (state, { hasChanges }) => ({
    ...state,
    hasFormChanges: hasChanges,
  })),
  on(ApplicationActions.DiscardUnsavedChanges, (state) => ({
    ...state,
    hasFormChanges: false,
  })),
  on(AbsencePageActions.changePage, (state, { page }) => ({
    ...state,
    page: page
  })),
  on(AbsencePageActions.filterByAbsenceType, (state, { absenceTypeIds }) => ({
    ...state,
    filteredAbsenceTypes: absenceTypeIds
  })),
  on(AbsencePageActions.filterByStatus, (state, { status }) => ({
    ...state,
    filterByStatus: status
  })),
  on(AbsencePageActions.resetFilter, (state) => ({
    ...state,
    filteredAbsenceTypes: [],
    filterByStatus: -1
  }))
);



