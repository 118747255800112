import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { mergeMap, map, switchMap, filter, exhaustMap, Subject, catchError, of } from 'rxjs';
import { OrganizationService } from '@mentor-one-ui/core/services/api/organization.service';
import { UserOrganizationsAboutApiActions } from '@mentor-one-ui/user-organization/components/about/state/actions/about-api.action';
import { UserOrganizationPageActions } from '@mentor-one-ui/user-organization/components/about/state/actions/about-page.action';
import {
  SettingsApiActions,
  SettingsModalActions,
  SettingsPageActions,
} from '@mentor-one-ui/user-organization/components/settings/state/settings.actions';
import { AboutModalComponent } from '../about-modal/about-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { organizationDetailsSelectors } from './organization-details.selectors';
import { AbsenceManagementModalComponent } from '../../absence-management-module/absence-management-modal.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { DocumentationSettingsModalComponent } from '../../settings/documentation-settings/documentation-settings-modal/documentation-settings-modal.component.component';
import { ProfileModalComponent } from '../../profile/profile-modal/profile-modal.component';
import { UserActions } from '@mentor-one-ui/core/state/user/user.actions';
import { selectUserRole } from '@mentor-one-ui/core/state/access/access.selector';
import { EmployeeEmploymentTabActions } from '@mentor-one-ui/employees/state/actions/employee-employment.actions';
import { NewEmployeePageActions } from '@mentor-one-ui/employees/new-employee/state/actions/new-employee-page-actions';

@Injectable()
export class OrganizationDetailsEffects {
  constructor(
    private actions$: Actions,
    private dialogService: DialogService,
    private store: Store,
    private organizationService: OrganizationService,
    private confirmationService: ConfirmationService,
    private translationService: TranslationDataService,
    private messageService: MessageService
  ) {}

  // About Form

  loadOrganizationAboutForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.organizationService.GetOrganization().pipe(
          map((data: any) => {
            return UserOrganizationsAboutApiActions.loadUserOrganizationSuccess({ organization: data });
          }),
          catchError((err) => {
            return of(UserOrganizationsAboutApiActions.loadUserOrganizationFailure({ error: err.error }));
          })
        )
      )
    );
  });

  saveOrganizationAboutForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserOrganizationPageActions.saveUserOrganization, SettingsPageActions.saveSettings),
      mergeMap((action) => {
        return this.organizationService.UpdateOrganization(action.organization).pipe(
          map((res) => {
            return SettingsApiActions.saveSettingsSuccess({ organization: res });
          }),
          catchError((err) => {
            return of(
              SettingsApiActions.saveSettingsFailure({
                error: this.translationService.translate('organization-save-error'),
              })
            );
          })
        );
      })
    );
  });

  showToastOnSave$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SettingsApiActions.saveSettingsSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('organization-saved'),
          });
        })
      );
    },
    { dispatch: false }
  );

  showSaveOrganizationError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SettingsApiActions.saveSettingsFailure,
        UserOrganizationsAboutApiActions.saveAboutImageError,
        UserOrganizationsAboutApiActions.deleteAboutImageError
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: action.error,
        });
      })
    );
  });

  // About image

  loadOrganizationAboutImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserOrganizationPageActions.loadAboutImage, UserOrganizationsAboutApiActions.saveAboutImageSuccess),
      mergeMap(() =>
        this.organizationService.GetOrganizationImage().pipe(
          map((data: any) => {
            return UserOrganizationPageActions.setAboutImage({ localImageUrl: data });
          }),
          catchError((err) => {
            return of(UserOrganizationsAboutApiActions.loadOrganizationImageFailed({ error: err.error }));
          })
        )
      )
    );
  });

  saveOrganizationAboutImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserOrganizationPageActions.saveAboutImage),
      mergeMap((action) =>
        this.organizationService.UploadOrganizationImageFile(action.imageFile).pipe(
          map((data: any) => {
            return UserOrganizationsAboutApiActions.saveAboutImageSuccess();
          }),
          catchError((err) => {
            return of(
              UserOrganizationsAboutApiActions.saveAboutImageError({
                error: this.translationService.translate('upload-image-failed'),
              })
            );
          })
        )
      )
    );
  });

  showToastOnProfileImageUploadSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserOrganizationsAboutApiActions.saveAboutImageSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('upload-image-success'),
          });
        })
      );
    },
    { dispatch: false }
  );

  deleteOrganizationAboutImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserOrganizationPageActions.deleteAboutImage),
      mergeMap(() =>
        this.organizationService.DeleteOrganizationImage().pipe(
          map(() => {
            return UserOrganizationsAboutApiActions.deleteAboutImageSuccess();
          }),
          catchError((err) => {
            return of(
              UserOrganizationsAboutApiActions.deleteAboutImageError({
                error: this.translationService.translate('delete-image-failed'),
              })
            );
          })
        )
      )
    );
  });

  showToastOnProfileImageDeleteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserOrganizationsAboutApiActions.deleteAboutImageSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('delete-image-success'),
          });
        })
      );
    },
    { dispatch: false }
  );

  // Organization Map
  loadOrganizationMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserOrganizationPageActions.loadOrganizationMap,
        UserOrganizationsAboutApiActions.saveOrganizationMapSuccess
      ),
      mergeMap(() =>
        this.organizationService.GetOrganizationMap().pipe(
          map((data: any) => {
            return UserOrganizationsAboutApiActions.loadOrganizationMapSuccess({ imageFile: data });
          }),
          catchError((err) => {
            return of(UserOrganizationsAboutApiActions.loadOrganizationMapFailure({ error: err.error }));
          })
        )
      )
    );
  });

  saveOrganizationMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserOrganizationPageActions.saveOrganizationMap),
      mergeMap((action) =>
        this.organizationService.UploadOrganizationMapFile(action.imageFile).pipe(
          map((data: any) => {
            return UserOrganizationsAboutApiActions.saveOrganizationMapSuccess();
          }),
          catchError((err) => {
            return of(
              UserOrganizationsAboutApiActions.saveOrganizationMapError({
                error: this.translationService.translate('upload-image-failed'),
              })
            );
          })
        )
      )
    );
  });

  showToastOnImageUploadSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserOrganizationsAboutApiActions.saveOrganizationMapSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('upload-image-success'),
          });
        })
      );
    },
    { dispatch: false }
  );

  imageApiErrorMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserOrganizationsAboutApiActions.saveOrganizationMapError,
        UserOrganizationsAboutApiActions.deleteOrganizationMapError
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: action.error,
        });
      })
    );
  });

  deleteOrganizationMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserOrganizationPageActions.deleteOrganizationMap),
      mergeMap(() =>
        this.organizationService.DeleteOrganizationMap().pipe(
          map(() => {
            return UserOrganizationsAboutApiActions.deleteOrganizationMapSuccess();
          }),
          catchError((err) => {
            return of(
              UserOrganizationsAboutApiActions.deleteOrganizationMapError({
                error: this.translationService.translate('delete-image-failed'),
              })
            );
          })
        )
      )
    );
  });

  showToastOnImageDeleteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserOrganizationsAboutApiActions.deleteOrganizationMapSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('delete-image-success'),
          });
        })
      );
    },
    { dispatch: false }
  );

  loadOrganizationStats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserOrganizationPageActions.loadOrganizationStats),
      concatLatestFrom(() => this.store.select(selectUserRole)),
      filter(([action, role]) => role.isMentorPersonalAdministrator! || role.isPersonnelManager!),
      mergeMap(() =>
        this.organizationService.LoadOrganizationStats().pipe(
          map((data: any) => {
            return UserOrganizationsAboutApiActions.loadOrganizationStatsSuccess({ stats: data });
          })
        )
      )
    );
  });

  dialogRef: any;
  showOrganizationDetailsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOrganizationPageActions.ShowDetailsModal),
      concatLatestFrom(() => this.store.select(organizationDetailsSelectors.selectSelectedUserOrganization)),
      switchMap(([action, organization]) => {
        let header = organization?.Name;

        this.dialogRef = this.dialogService.open(AboutModalComponent, {
          header,
        });

        return this.dialogRef.onClose;
      }),
      map((data) => {
        return UserOrganizationPageActions.DetailsModalWasClosed();
      })
    )
  );

  loadPersonnelManagers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserActions.bootstrapApplicationData,
        EmployeeEmploymentTabActions.OpenEmploymentModal,
        NewEmployeePageActions.Enter
      ),
      mergeMap((action) =>
        this.organizationService.getPersonnelManagers().pipe(
          map((result) => {
            return UserActions.LoadPersonnelManagersSuccess({ managers: result });
          }),
          catchError((err) => of(UserActions.LoadPersonnelManagersError(err.message)))
        )
      )
    );
  });

  showOrganizationVisionDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOrganizationPageActions.ShowProfileModal),
      concatLatestFrom(() => this.store.select(organizationDetailsSelectors.selectSelectedUserOrganization)),
      switchMap(([action, organization]) => {
        let header = this.translationService.translate('vision');

        this.dialogRef = this.dialogService.open(ProfileModalComponent, {
          header,
        });

        return this.dialogRef.onClose;
      }),
      map((data) => {
        return UserOrganizationPageActions.ProfileModalWasClosed();
      })
    )
  );

  dialogClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UserOrganizationPageActions.HideDetailsModal,
          UserOrganizationPageActions.HideProfileModal,
          UserOrganizationsAboutApiActions.saveUserOrganizationSuccess,
          SettingsModalActions.CloseModalConfirmed,
          SettingsApiActions.saveSettingsSuccess
        ),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );

  openAbsenceModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsPageActions.openAbsenceModal),
      concatLatestFrom(() => this.store.select(organizationDetailsSelectors.selectSelectedUserOrganization)),
      map(([action, org]) => {
        let header = this.translationService.translate('absence-and-workinghours');

        this.dialogRef = this.dialogService.open(AbsenceManagementModalComponent, {
          header,
        });

        return this.dialogRef.onClose;
      }),
      map((data) => {
        return UserOrganizationPageActions.DetailsModalWasClosed();
      })
    )
  );
  openFormalityModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsPageActions.openFormalityModal),
      concatLatestFrom(() => this.store.select(organizationDetailsSelectors.selectSelectedUserOrganization)),
      switchMap(([action, org]) => {
        let header = this.translationService.translate('documentation');

        this.dialogRef = this.dialogService.open(DocumentationSettingsModalComponent, {
          header,
        });

        return this.dialogRef.onClose;
      }),
      map((data) => {
        return UserOrganizationPageActions.DetailsModalWasClosed();
      })
    )
  );

  cancelModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingsModalActions.CancelModal),
      filter((action) => action.hasChanges),
      exhaustMap((_) => {
        const confirmationResult = new Subject<boolean>();

        this.confirmationService.confirm({
          header: this.translationService.translate('cancel'),
          message: this.translationService.translate('cancel-confirm'),
          icon: 'pi pi-exclamation-triangle',
          rejectButtonStyleClass: 'p-button-text',
          accept: () => {
            this.store.dispatch(ApplicationActions.DiscardUnsavedChanges());
            confirmationResult.next(true);
            confirmationResult.complete();
          },
          reject: () => {
            confirmationResult.next(false);
            confirmationResult.complete();
          },
        });
        return confirmationResult.asObservable();
      }),
      map((result: boolean) => {
        return result ? SettingsModalActions.CloseModalConfirmed() : SettingsModalActions.CloseModalRejected();
      })
    );
  });

  cancelModalWithoutChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingsModalActions.CancelModal),
      filter((action) => !action.hasChanges),
      map((_) => {
        return SettingsModalActions.CloseModalConfirmed();
      })
    );
  });
}
