import { createAction, props } from "@ngrx/store";
import { AbsenceType } from "../models/absence-type.model";
import { AbsenceModel } from "../models/absence.model";

export const AbsenceApiActions = {
    loadAbsenceTypesSuccess: createAction('[Absence API] Load Absence Types Success',
      props<{ absenceTypes: AbsenceType[] }>()),
    loadAbsenceTypesError: createAction('[Absence API] Load Absence Types Error',
      props<{ message: string}>()),

    loadAbsencesSuccess: createAction('[Absence API] Load Absences Success',
      props<{ absences: AbsenceModel[], totalItems: number }>()),
    loadAbsencesError: createAction('[Absence API] Load Absences Error',
      props<{ message: string}>()),

    registerAbsenceSuccess: createAction('[Absence API] Register Absence Success',
      props<{ absence: AbsenceModel }>()),
    registerAbsenceError: createAction('[Absence API] Register Absence Error', props<{ message: string}>()),

    UpdateAbsenceSuccess: createAction('[Absence API] Update Absence Success',
      props<{ absence: AbsenceModel }>()),

    UpdateAbsenceError: createAction('[Absence API] Update Absence Error', props<{ message: string}>()),

    DeleteAbsenceSuccess: createAction('[Absence API] Delete Absence Success',
      props<{ absenceId: number }>()),
    DeleteAbsenceError: createAction('[Absence API] Delete Absence Error', props<{ message: string}>()),

};
