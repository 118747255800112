import { createReducer, on } from '@ngrx/store';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { UserOrganizationsAboutApiActions } from '@mentor-one-ui/user-organization/components/about/state/actions/about-api.action';
import { StatsModel } from '@mentor-one-ui/core/models/StatsModel';
import { UserOrganizationPageActions } from '@mentor-one-ui/user-organization/components/about/state/actions/about-page.action';
import { SettingsApiActions } from '../../settings/state/settings.actions';
import { FrontPageApiActions } from '@mentor-one-ui/core/state/frontpage/frontpage.actions';

export const featureKey = 'organization-details';

export interface State {
  userOrganization: UserOrganizationModel | null;
  organizationImage: string | null;
  stats: StatsModel | null;
  isSaving: boolean;
  loadError: boolean;
  loading: boolean;
}

export const initialState: State = {
  userOrganization: null,
  organizationImage: null,
  stats: null,
  isSaving: false,
  loadError: false,
  loading: false,
};

export const reducer = createReducer<State>(
  initialState,
  on(UserOrganizationsAboutApiActions.loadUserOrganizationSuccess, (state, action): State => {        
    return {
      ...state,
      userOrganization: action.organization,
      loading: false,
    };
  }),
  on(
    UserOrganizationsAboutApiActions.loadUserOrganizationFailure,
    UserOrganizationsAboutApiActions.loadOrganizationImageFailed,
    UserOrganizationsAboutApiActions.loadOrganizationMapFailure, (state): State => {    
    return {
      ...state,
      loading: false,
      loadError: true,
    };
  }),
  on(UserOrganizationPageActions.saveUserOrganization, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(
    UserOrganizationsAboutApiActions.saveUserOrganizationSuccess,
    SettingsApiActions.saveSettingsSuccess,    
    (state, action): State => {
      return {
        ...state,
        userOrganization: action.organization,
        isSaving: false,
      };
    }
  ),
  on(SettingsApiActions.saveSettingsFailure, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(UserOrganizationPageActions.setAboutImage, (state, action): State => {
    return {
      ...state,
      organizationImage: action.localImageUrl,
    };
  }),
  on(UserOrganizationsAboutApiActions.deleteAboutImageSuccess, (state): State => {
    return {
      ...state,
      organizationImage: ''
    };
  }),
  on(UserOrganizationsAboutApiActions.loadOrganizationMapSuccess, (state, action): State => {
    if (action.imageFile == null) action.imageFile = '';
    let entity = JSON.parse(JSON.stringify(state.userOrganization));
    entity.Map = action.imageFile;
    return {
      ...state,
      userOrganization: entity,
    };
  }),
  on(UserOrganizationPageActions.setOrganizationMap, (state, action): State => {
    let entity = JSON.parse(JSON.stringify(state.userOrganization)); // TODO check if needed
    entity.Map = action.localImageUrl;
    return {
      ...state,
      userOrganization: entity,
    };
  }),
  on(UserOrganizationsAboutApiActions.deleteOrganizationMapSuccess, (state): State => {
    let entity = JSON.parse(JSON.stringify(state.userOrganization));
    entity.Map = null;
    return {
      ...state,
      userOrganization: entity,
    };
  }),
  on(UserOrganizationsAboutApiActions.loadOrganizationStatsSuccess, (state, action): State => {
    return {
      ...state,
      stats: action.stats,
    };
  }),
  on(FrontPageApiActions.CloseStartupSupportBoxSuccess, (state): State => {
    let entity = JSON.parse(JSON.stringify(state.userOrganization));
    entity.HideSupportBox = true;
    return {
      ...state,
      userOrganization: entity
    };
  })
);
