import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { createAction, props } from '@ngrx/store';

export const EmployeeListApiActions = {
  //
  LoadEmployeesSuccess: createAction('[Employee List API] Load list success', props<{ employees: EmployeeModel[] }>()),
  LoadEmployeesError: createAction('[Employee List API] Load list error', props<{ error: string }>()),

  LoadArchivedEmployeesSuccess: createAction(
    '[Employee List API] Load Archived Employees Success',
    props<{ employees: EmployeeModel[] }>()
  ),
  LoadArchivedEmployeesError: createAction(
    '[Employee List API] Load Archived Employees Error', 
    props<{ error: string }>()),

  //
  LoadDepartmentsSuccess: createAction('[Employee List API] Load Departments Success', props<{ departments: any[] }>()),
  LoadDepartmentsError: createAction('[Employee List API] Load Departments Error', props<{ error: string }>()),

  //
  ArchiveEmployeeSuccess: createAction('[Employee List API] Archive Employee Success', props<{ employeeId: number }>()),
  ArchiveEmployeeError: createAction('[Employee List API] Archive Employee Error', props<{ error: string }>()),

  //
  UnarchiveEmployeeSuccess: createAction('[Employee List API] Unarchive Employee Success', props<{ id: number }>()),
  UnarchiveEmployeeError: createAction('[Employee List API] Unarchive Employee Error', props<{ error: string }>()),

  //
  ActivateToggleEmployeeSuccess: createAction(
    '[Employee List API] Activate Employee Success',
    props<{ employeeId: number; isActive: boolean }>()
  ),
  ActivateToggleEmployeeError: createAction('[Employee List API] Activate Employee Error', props<{ error: string }>()),

  //
  DeleteEmployeeSuccess: createAction('[Employee List API] Delete Employee Success', props<{ employeeId: number }>()),
  DeleteEmployeeError: createAction('[Employee List API] Delete Employee Error', props<{ error: string }>()),

  //
  ArchiveEmployeeListSuccess: createAction(
    '[Employee List API] Archive Employees List Success',
    props<{ employees: EmployeeModel[] }>()
  ),

  ArchiveEmployeeListError: createAction('[Employee List API] Archive Employees Error', props<{ error: string }>()),

  //
  ActivateToggleEmployeeListSuccess: createAction(
    '[Employee List API] Activate Employees List Success',
    props<{ employees: EmployeeModel[]; isActive: boolean }>()
  ),

  ActivateToggleEmployeeListError: createAction(
    '[Employee List API] Activate Employees Error',
    props<{ error: string }>()
  ),
};
