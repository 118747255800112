import { createAction, props } from "@ngrx/store";
import { OvertimeModel } from "../../../models/overtime.model";

export const NewOvertimePageActions = {
  Enter: createAction('[New Overtime Page] Enter'),
  SelectOvertimeType: createAction('[New Overtime Page] Select Overtime Type',
  props<{ overtimeTypeId: number }>()),
  ResetOvertimeTypeSelection: createAction('[New Overtime Page] Reset Overtime Type Selection'),
  Register: createAction('[New Overtime Page] Register',
  props<{ overtime: OvertimeModel }>()),
  GoBack: createAction('[New Overtime Page] Go Back')

};

