import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { Observable, of } from 'rxjs';
import { UserModel, UserTokenModel } from 'src/app/core/state/user/user.model';
import { environment } from 'src/environments/environment';
import { DropdownModel } from '../../models/DropdownModel';
import { TranslationDataService } from '../translation-data.service';
import { SignInRequestModel } from '@mentor-one-ui/set-password/state/set-password.model';
import { CreateSignInRequestModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public http: HttpClient, private translationDataService: TranslationDataService) {}

  GetUserData(): Observable<UserModel> {
    return this.http.get<UserModel>(environment.API_URL + '/user/userinfo');
  }

  GetUserToken(): Observable<UserTokenModel> {
    return this.http.get<UserTokenModel>(environment.API_URL + '/user/usertoken');
  }

  GetAvailableOrganizations(): Observable<UserOrganizationModel[]> {
    return this.http.get<UserOrganizationModel[]>(environment.API_URL + '/user/organizations');
  }

  SwitchOrganization(organizationId: number): Observable<any> {
    return this.http.get<any>(environment.API_URL + '/useropen/switchorganization?organizationId=' + organizationId);
  }

  getManageableEmployees(): Observable<number[]> {
    return this.http.get<number[]>(environment.API_URL + '/User/manageable/employees');
  }

  getJobTitles(): Observable<string[]> {
    return this.http.get<string[]>(environment.API_URL + '/Employee/JobTitles');
  }

  createSignInRequest(empoloyeeId: number, roleIds: number[]): Observable<number> {
    let signInRequest: CreateSignInRequestModel = { EmployeeId: empoloyeeId, RoleIds: roleIds };
    return this.http.post<number>(environment.API_URL + '/User/' + empoloyeeId + '/CreateSignInRequest', signInRequest);
  }

  resendSignInRequest(employeeId: number): Observable<any> {
    return this.http.post<any>(environment.API_URL + '/User/' + employeeId + '/ResendSignInRequest', null);
  }

  deleteUserAccess(employeeId: number): Observable<any> {
    return this.http.delete<any>(environment.API_URL + '/User/' + employeeId + '/DeleteUserLogin');
  }

  GetSectorData(): Observable<DropdownModel[]> {
    return of([
      { name: this.translationDataService.translate('kindergarden'), value: 1 },
      { name: this.translationDataService.translate('school'), value: 2 },
      { name: this.translationDataService.translate('general'), value: 3 },
      { name: this.translationDataService.translate('hotel-and-restaurant'), value: 4 },
    ]);
  }

  CheckIfTokenIsCompleted(token: string): Observable<boolean> {
    let signInRequest: SignInRequestModel = { Token: token, Password: '' };
    return this.http.post<boolean>(environment.API_URL + '/UserOpen/CheckIfSignInRequestIsCompleted', signInRequest);
  }

  SetPassword(token: string, password: string): Observable<string> {
    let signInRequest: SignInRequestModel = { Token: token, Password: password };
    return this.http.post<string>(environment.API_URL + '/UserOpen/CreateLoginFromSignInRequest', signInRequest);
  }

  SetCultureCode(cultureCode: string): Observable<any> {
    return this.http.post<any>(environment.API_URL + '/User/SetCultureCode/' + cultureCode, null);
  }
}
