import { AccessControlSystemEditModel } from '@mentor-one-ui/core/models/frontpage.model';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { createAction, props } from '@ngrx/store';
import { UserModel, UserTokenModel } from './user.model';
import { DropdownModel } from '@mentor-one-ui/core/models/DropdownModel';
import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';

const loadCurrentUser = createAction('[User] Load Current User');
const loadUserDataSuccess = createAction('[User] Load user success', props<{ payload: UserModel }>());
const loadAvailableOrganzationStarted = createAction('[User] Load available organizations started');
const loadAvailableOrganzationSuccess = createAction(
  '[User] Load available organizations success',
  props<{ payload: UserOrganizationModel[] }>()
);
const switchOrganzation = createAction('[User] Switch organization', props<{ organizationId: number }>());
const setCultureCode = createAction('[User] Set culture code', props<{ cultureCode: string }>());
const setCultureCodeSuccess = createAction('[User] Set culture code success');
const resetStateAfterOrganizationSwitch = createAction('[User] Reset state after switch organization');
const loadAccessControl = createAction('[User] Frontpage load access control start');
const loadAccessControlSuccess = createAction(
  '[User] Frontpage load access control success',
  props<{ payload: AccessControlSystemEditModel[] }>()
);

const loadSectorDataSuccess = createAction('[User] Load sectors success', props<{ sectors: DropdownModel[] }>());

const loadManageableEmployeesSuccess = createAction('[User] Load manageable employees success', props<{ employeeIds: number[] }>());

const loadJobTitlesSuccess = createAction('[User] Load job titles success', props<{ jobTitles: string[] }>());

const bootstrapApplicationData = createAction('[User] Bootstrap Application Data');



export const UserActions = {
  loadCurrentUser,  
  loadUserDataSuccess,
  loadAccessControl,
  loadAccessControlSuccess,
  switchOrganzation,
  setCultureCode,
  setCultureCodeSuccess,
  resetStateAfterOrganizationSwitch,
  loadAvailableOrganzationStarted,
  loadAvailableOrganzationSuccess,
  loadSectorDataSuccess,
  loadManageableEmployeesSuccess,
  loadJobTitlesSuccess,
  bootstrapApplicationData,  
  LoadPersonnelManagersSuccess: createAction(
    '[Employee - Employment API] Load PersonnelManagers success',
    props<{ managers: PersonnelManagerModel[] }>()
  ),
  LoadPersonnelManagersError: createAction(
    '[Employee - Employment API] Load PersonnelManagers error',
    props<{ error: string }>()
  ),
};

const loadUserTokenSuccess = createAction('[User] Load User Token Success', props<{ userToken: UserTokenModel }>());
const loadUserTokenError = createAction('[User] Load User Token Error', props<{ error: string }>());
export const UserApiActions = {
  loadUserTokenSuccess,
  loadUserTokenError
}
