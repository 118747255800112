import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";
import { AbsenceModel } from "./models/absence.model";
import { AbsenceType } from "./models/absence-type.model";
import { TimeValidatorService } from "@mentor-one-ui/time/shared/time-validator.service";
import { AbsenceEmployeeListResponseModel } from "../overtime/models/absence-employee-list-response.model";

@Injectable({
  providedIn: 'root',
})
export class AbsenceService {
  constructor(public http: HttpClient,
    private timeValidatorService: TimeValidatorService) { }

  getAbsenceTypes(): Observable<AbsenceType[]> {
    return this.http.get<AbsenceType[]>(environment.API_URL + '/Absence/template/organization/list');
  }

  getAbsences(employeeId: number, absenceMapIds: number[], status: number | null, skip: number = 0 , take: number = 10): Observable<AbsenceEmployeeListResponseModel> {  
    const body = {
      AbsenceMapIds: absenceMapIds,
      Skip: skip,
      Take: take,   
      Status: (status != null && status > -1) ? status : null
    };

    return this.http.post<AbsenceEmployeeListResponseModel>(environment.API_URL + '/Absence/' + employeeId + '/list', body).pipe(
      map((response) => {
        response.Items.map((absence) => {
          absence.FromTime = this.timeValidatorService.removeSecondsFromTimeString(absence.FromTime);
          absence.ToTime = this.timeValidatorService.removeSecondsFromTimeString(absence.ToTime);
          return absence;
        });

        return response;
      })
    );
  }

  registerAbsence(employeeId: number, absence: AbsenceModel): Observable<AbsenceModel> {
    return this.http.post<AbsenceModel>(environment.API_URL + '/Absence/' + employeeId + '/add', absence);
  }

  updateAbsence(absence: AbsenceModel): Observable<AbsenceModel> {
    return this.http.post<AbsenceModel>(environment.API_URL + '/Absence/' + absence.AbsenceId + '/update', absence);
  }

  deleteAbsence(absenceId: number) {
    return this.http.delete(environment.API_URL + '/Absence/' + absenceId + '/delete');
  }

  getAbsence(id: number): Observable<AbsenceModel> {
    return this.http.get<AbsenceModel>(environment.API_URL + '/Absence/' + id).pipe(map((absence) => {
      absence.FromTime = this.timeValidatorService.removeSecondsFromTimeString(absence.FromTime);
      absence.ToTime = this.timeValidatorService.removeSecondsFromTimeString(absence.ToTime);
      return absence;
    }));
  }
}

