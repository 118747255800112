

import { PersonnelManagerModel } from "@mentor-one-ui/core/models/employee/EmploymentModel";
import { AbsenceModel } from "@mentor-one-ui/time/my-time/absence/models/absence.model";
import { OvertimeModel } from "@mentor-one-ui/time/my-time/overtime/models/overtime.model";
import { createAction, props } from "@ngrx/store";

export const ManageAddAbsencePageActions = {
  enter: createAction('[ManageAddAbsencePage] Enter'),
  resetSelection: createAction('[ManageAddAbsencePage] Reset selection'),
  selectEmployees: createAction(
    '[ManageAddAbsencePage] Select employees',
    props<{ employeeIds: number[] }>()
  ),
  startNewLeaveRegistration: createAction(
    '[ManageAddAbsencePage] Start new absence register'
  ),
  registerNewAbsence: createAction(
    '[ManageAddAbsencePage] Register new absence',
    props<{ absence: AbsenceModel, AutomaticApprove: boolean }>()
  ),
  registerNewOvertime: createAction(
    '[ManageAddAbsencePage] Register new overtime',
    props<{ overtime: OvertimeModel, AutomaticApprove: boolean }>()
  ),
  SelectAbsenceType: createAction(
    '[ManageAddAbsencePage] Select absence type',
    props<{ absenceTypeId: number }>()
  ),
  SelectOvertimeType: createAction(
    '[ManageAddAbsencePage] Select overtime type',
    props<{ overtimeTypeId: number }>()
  ),
  filterEmployeesByName: createAction(
    '[ManageAddAbsencePage] Filter employees by name',
    props<{ query: string }>()
  ),
  setCurrentUserAsPersonnelManager: createAction(
    '[Manage Add absence Page] Set Current User As Personnel Manager',
    props<{ personnelManager: PersonnelManagerModel }>()
  ),

};
