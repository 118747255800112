import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonBarButton, ButtonIconPosition } from '@mentor-one-ui/core/models/ButtonBar';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'mo-button-bar',
  styleUrls: ['./button-bar.scss'],
  template: `
    <div class="button-bar">
      <span *ngFor="let button of buttonList">
        <button
          *ngIf="!button.routerLink && button.visible"
          pButton
          type="button"
          (click)="handleButtonClick(button)"
          (routerLink)="(button.routerLink)"
          icon="{{ button.icon }}"
          [iconPos]="button.iconPos || iconPos"
          [class]="button.class"
          autofocus
          [loading]="(button?.isSaveButton && isSaving)!"
          [disabled]="disableButton(button.disabled) || isSaving || (button?.isSaveButton && !canSave) || disableButtons"
          [label]="button.label"
        ></button>
        <a pButton *ngIf="button.visible && button.routerLink" [routerLink]="button.routerLink">{{ button.label }}</a>
      </span>
    </div>
  `,
})
export class ButtonBarComponent implements OnInit {
  @Input() buttonList: ButtonBarButton[];
  @Input() isSaving: boolean;
  @Input() disableButtons: boolean;
  @Input() canSave: boolean = true;
  @Output() buttonBarClick: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  iconPos: 'left' | 'right' = 'left';

  constructor() {}

  ngOnInit(): void {

  }

  handleButtonClick(button: ButtonBarButton) {
    this.buttonBarClick.emit(button);
  }

  disableButton(value: Boolean) {
    return value ? 'disabled' : '';
  }
}
