import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { MentorAdminCreateInternalUser, MentorAdminOrganizationModel } from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { ValidateOrganizationModel } from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { ValidateOrganizationNumberRequest } from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { SectorEnum } from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { ContactModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactModel';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { AccessControlSystemEditModel } from '@mentor-one-ui/core/models/module-access';
import { map } from 'rxjs';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { TimeManagementTemplate } from '@mentor-one-ui/user-organization/components/settings/models/time-management-template.model';
import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { SupportLoginRequestModel } from '@mentor-one-ui/core/models/admin-organization/support-login.model';
import { SystemMessageModel } from '@mentor-one-ui/core/models/system-messages.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(public http: HttpClient) {}

  // API - MENTOR ADMIN - ORGANIZATION //////////

  getAdminOrganizations(): Observable<MentorAdminOrganizationModel[]> {
    return this.http.get<MentorAdminOrganizationModel[]>(environment.API_URL + '/mentoradminorganization/list');
  }
  getAdminOrganization(id: number): Observable<MentorAdminOrganizationModel> {
    return this.http.get<MentorAdminOrganizationModel>(environment.API_URL + '/mentoradminorganization/' + id);
  }

  deleteAdminOrganization(id: number) {
    return this.http.delete(environment.API_URL + '/mentoradminorganization/' + id + '/delete');
  }

  createMentorAdmin(employee: MentorAdminCreateInternalUser): Observable<number> {
    return this.http.post<number>(environment.API_URL + '/mentoradminorganization/createinternaluser', employee);
  }

  validateOrganization(organization: ValidateOrganizationNumberRequest): Observable<ValidateOrganizationModel> {
    return this.http.post<ValidateOrganizationModel>(
      environment.API_URL + '/mentoradminorganization/validateorganizationnumbers',
      organization
    );
  }

  supportLoginRequest(supportRequest: SupportLoginRequestModel): Observable<any> {
    return this.http.post<any>(environment.API_URL + '/mentoradminorganization/supportlogin/start', supportRequest);
  }

  supportLogoutRequest(sid: number): Observable<any> {
    return this.http.post<any>(environment.API_URL + '/MentorAdminOrganization/supportlogin/' + sid + '/end', null);
  }

  saveOrganization(mentorAdminOrganization: MentorAdminOrganizationModel): Observable<MentorAdminOrganizationModel> {
    return this.http.post<MentorAdminOrganizationModel>(
      environment.API_URL + '/mentoradminorganization/update',
      mentorAdminOrganization
    );
  }

  getModuleAccessDefault(): Observable<AccessControlSystemEditModel[]> {
    return this.http.get<AccessControlSystemEditModel[]>(
      environment.API_URL + '/MentorAdminOrganization/moduleaccessdefaults'
    );
  }

  getSystemMessageList(): Observable<SystemMessageModel[]> {
    return this.http.get<SystemMessageModel[]>(environment.API_URL + '/Organization/systemmessages');
  }

  CloseSupportBoxOnFrontpage() {
    return this.http.post(environment.API_URL + '/organization/hidesupportbox', null);
  }

  getTimeManagementTypes(): Observable<any> {
    return this.http.get<any>(environment.API_URL + '/organization/timemanagement/list').pipe(map((result) => {
      let absences = result.AbsenceTemplates.map((m: any) => {
        return {...m, TimeManagementType: TimeManagementType.Absence} as TimeManagementTemplate;
      });
      let overtimes = result.OvertimeTemplates.map((m: any) => {
        return {...m, TimeManagementType: TimeManagementType.Overtime} as TimeManagementTemplate;
      });
      return [...absences, ...overtimes];
    } ));
  }

  updateOvertimeTypes(timeManagementTypes: TimeManagementTemplate[]): Observable<TimeManagementTemplate[]> {
    const body = {
      Templates: timeManagementTypes,
    };
    return this.http.post<TimeManagementTemplate[]>(environment.API_URL + '/organization/timemanagement/overtime/update', body)
      .pipe(map((result: any) => {
        let overtimeTypes = result.map((m: any) => {
          return {...m, TimeManagementType: TimeManagementType.Overtime} as TimeManagementTemplate;
        });
        return overtimeTypes
      } ));
  }

  updateAbsenceTypes(timeManagementTypes: TimeManagementTemplate[]): Observable<any> {
    const body = {
      Templates: timeManagementTypes,
    };
    return this.http.post<TimeManagementTemplate[]>(environment.API_URL + '/organization/timemanagement/absence/update', body)
      .pipe(map((result: any) => {
        let absenceTypes = result.map((m: any) => {
          return {...m, TimeManagementType: TimeManagementType.Absence} as TimeManagementTemplate;
        });
        return absenceTypes
      } ));
  }

  getContacts(): Observable<any> {
    return this.http.get<ContactModel[]>(environment.API_URL + '/organization/contacts');
  }
  postContact(contact: ContactModel): Observable<any> {
    return this.http.post<ContactModel[]>(environment.API_URL + '/organization/contact/', contact);
  }
  deleteContact(contact: ContactModel): Observable<any> {
    return this.http.delete<ContactModel>(environment.API_URL + '/organization/contact/' + contact.ContactId);
  }


  getPersonnelManagers(): Observable<PersonnelManagerModel[]> {
    return this.http.get<PersonnelManagerModel[]>(environment.API_URL + '/organization/PersonnelManagers/');
  }


  //// AboutComponent //////////////////////

  UploadOrganizationImageFile(file: File): Observable<any> {
    const res = this.http.post<File>(environment.API_URL + '/organization/profilepicture/save', file);
    return res;
  }
  GetOrganizationImage(): Observable<any> {
    const res = this.http.get<Blob>(environment.API_URL + '/organization/profilepicture/', {
      responseType: 'text' as 'json',
    });
    return res;
  }
  DeleteOrganizationImage(): Observable<any> {
    const res = this.http.delete(environment.API_URL + '/organization/profilepicture/delete');
    return res;
  }

  GetOrganization(): Observable<any> {
    const res = this.http.get(environment.API_URL + '/organization/get');
    return res;
  }

  UpdateOrganization(organization: UserOrganizationModel): Observable<UserOrganizationModel> {
    return this.http.post<UserOrganizationModel>(environment.API_URL + '/organization/update', organization);
  }

  UploadOrganizationMapFile(file: File): Observable<any> {
    return this.http.post<File>(environment.API_URL + '/organization/OrganizationalChartPicture/save', file);
  }
  GetOrganizationMap(): Observable<any> {
    const res = this.http.get<Blob>(environment.API_URL + '/organization/OrganizationalChartPicture/', {
      responseType: 'text' as 'json',
    });
    return res;
  }
  DeleteOrganizationMap(): Observable<any> {
    const res = this.http.delete(environment.API_URL + '/organization/OrganizationalChartPicture/delete');
    return res;
  }
  LoadOrganizationStats(): Observable<any> {
    const res = this.http.get(environment.API_URL + '/organization/stats');
    return res;
  }

  // UTILS ////////////////////////////////

  getSectorTypeEnum(sectorType: SectorEnum): string {
    if (sectorType == undefined) return '';
    const i = parseInt(sectorType.toString());
    return SectorEnum[i];
  }
}
