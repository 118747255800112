import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PipesModule } from '@mentor-one-ui/core/pipes/pipes.module';
import { ApplicationInsightsService } from '@mentor-one-ui/core/services/application-insights.service';
import { ButtonModule } from 'primeng/button';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mo-error-message',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    PipesModule
  ],
  styleUrls: ['./error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="error-container panel">
      <div class="text-icon"> 
        <h1>500</h1>
        <div class="icon">
        🥴
        </div>
      </div>
      <h2>{{ 'could-not-load-data-header' | translate | async}}</h2>
      <p>{{ 'could-not-load-data-text' | translate | async}}</p>
      <p class="code">{{getSessionId()}}</p>
      <a pButton href="mailto:{{getSupportEmail()}}?subject=Feilkode: {{getSessionId()}}" class="btn btn-primary">{{'contact-support' | translate | async}}</a>
    </div>
  `,
})
export class ErrorMessageComponent {

  constructor(private applicationInsights: ApplicationInsightsService) { }
  
  getSupportEmail() {
    return environment.SUPPORT_EMAIL;
  }

  getSessionId() {
    return this.applicationInsights.GetSessionId();
  }
 
 }
