import { TimeFilter } from "../../../shared/time-filter.model"
import { SortDirection } from "../../models/time-management-list-sorting.model"

export const getDefaultFilter = () => {
    return {
      absenceMapIds: [],
      overtimeMapdIds: [],
      selectedDepartmentIds: [],
      selectedPositions: [],
      selectedSortOptionId: 3,
      selectedEmployeeId: -1,
      statuses: [-1],
      employeeSearch: '',
      sortOptions: [       
      {
        id: 0,
        title: 'date',
        direction: SortDirection.Ascending
      },
      {
        id: 1,
        title: 'date',
        direction: SortDirection.Descending
      },
      {
        id: 2,
        title: 'RegistrationDate',
        direction: SortDirection.Ascending
      },
      {
        id: 3,
        title: 'RegistrationDate',
        direction: SortDirection.Descending
      }, 
      {
        id: 4,
        title: 'EmployeeName',
        direction: SortDirection.Ascending
      },{
        id: 5,
        title: 'EmployeeName',
        direction: SortDirection.Descending
      },   
    ]
    } as TimeFilter
  }