import { AccessControlSystemEditModel } from '@mentor-one-ui/core/models/frontpage.model';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { UserModel } from './user.model';
import { DropdownModel } from '@mentor-one-ui/core/models/DropdownModel';
import { SystemAccessRole } from '@mentor-one-ui/core/models/employee/SystemAccessRole';
import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';

export interface UserState {
  employeeId: number;
  user: UserModel | null;
  moduleAccess: AccessControlSystemEditModel[];
  availableOrganizations: UserOrganizationModel[];
  sectors: DropdownModel[];
  manageableEmployees: number[];
  personnelManagers: PersonnelManagerModel[];
  jobTitles: string[];
  availableSystemAccessRoles: SystemAccessRole[];
  manageableEmployeesLoaded: boolean;
}

export const initialState: UserState = {
  employeeId: -1,
  user: null,
  moduleAccess: [],
  availableOrganizations: [],
  personnelManagers: [],
  sectors: [],
  manageableEmployees: [],
  jobTitles: [],
  availableSystemAccessRoles: [],
  manageableEmployeesLoaded: false,
};
